import { ICreateCustomerData } from 'types/users'
import axios from 'utils/axios'
import { API_USERS_BASE_URL, API_USERS_V3_BASE_URL } from '../config'

export const getCustomers = (workspaceId: number): Promise<any> => axios.get(`${API_USERS_BASE_URL}/pages/${workspaceId}/customers`)

export const removeCustomer = (workspaceId: number, userId: number): Promise<any> => axios.delete(`${API_USERS_BASE_URL}/pages/${workspaceId}/customers/${userId}`)

export const searchCustomers = (workspaceId: number, query: string): Promise<any> => axios.post(`${API_USERS_V3_BASE_URL}/pages/${workspaceId}/search`, { query, customerType: "1",
  properties: ["1"] })

export const updateCustomer = (workspaceId: number, userId: number, body: object): Promise<any> => axios.put(`${API_USERS_BASE_URL}/pages/${workspaceId}/customers/${userId}`, body)

export const createCustomer = (workspaceId: number, body: ICreateCustomerData): Promise<any> => axios.post(`${API_USERS_BASE_URL}/pages/${workspaceId}/customers`, body)