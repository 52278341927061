import * as actions from 'actions/usersActions'
import { IAction, IUserState } from 'types/redux'

const initialState = {
  data: null,
  searchString: '',
  searchData: null,
  createdUserId: null,
  isLoading: false,
  error: null,
}

const users = (state: IUserState = initialState, action: IAction): Object => {
  switch (action.type) {
    case actions.GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        // isLoading: true,
      }
    case actions.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      }
    case actions.GET_CUSTOMERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.data
      }
    case actions.SEARCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        searchData: action.data.customers
      }
    case actions.RESET_SEARCH_DATA:
      return {
        ...state,
        searchData: null
      }
    case actions.DELETE_CUSTOMERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.DELETE_CUSTOMERS_SUCCESS:
      const dataAfterDelete = state.data?.filter(x => x.id !== action.data)
      return {
        ...state,
        isLoading: false,
        data: dataAfterDelete
      }
    case actions.DELETE_CUSTOMERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.EDIT_INTERNAL_USER:
      const { val, id, userId } = action.data
      const newData = state.data?.map((el, i) => {
        const currUserIndex = state.data?.findIndex(item => item.id === userId)

        if (i === currUserIndex) {
          const currPropIndex = el.properties?.findIndex(item => item.id === id)

          if (currPropIndex !== -1) {
            // eslint-disable-next-line no-param-reassign
            el.properties[currPropIndex].value = val
          }

          return el
        }

        return el
      })

      return {
        ...state,
        data: newData
      }
    case actions.CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case actions.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        data: [...state.data ?? [], action.data],
        isLoading: false,
        error: null,
      }
    case actions.CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actions.RESET_CREATED_USER:
      return {
        ...state,
        createdUserId: null
      }
    default:
      return state
  }
}

export default users