import { put, call, takeLatest, select, debounce } from "redux-saga/effects"
import * as actions from 'actions/usersActions'
import * as api from 'api/usersApi'
import { IAction } from "types/redux"
import { getUserProperties, getWorkspaceActiveId } from "selectors"
import { refreshAccessToken } from './authSaga'

function* getCustomers({ data }: IAction) {
  try {
    const workspaceId = yield select(getWorkspaceActiveId)
    const response = yield call(api.searchCustomers, workspaceId, data)

    yield put(actions.getUsersSuccess(response.data.customers))
  } catch (e: any) {
    if (e.errors && e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.getUsers(data))
    } else {
      yield put(actions.getUsersError(e.errors))
    }
  }
}

function* searchCustomers({ data }: IAction) {
  try {
    const workspaceId = yield select(getWorkspaceActiveId)
    const response = yield call(api.searchCustomers, workspaceId, data)

    yield put(actions.searchUsersSuccess(response.data))
  } catch (e: any) {
    if (e.errors && e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.searchUsersRequest(data))
    } else {
      yield put(actions.searchUsersError(e.errors))
    }
  }
}

function* deleteCustomer({ data }: IAction) {
  try {
    const workspaceId = yield select(getWorkspaceActiveId)
    yield call(api.removeCustomer, workspaceId, data)

    yield put(actions.deleteCustomerSuccess(data))
  } catch (e: any) {
    if (e.errors && e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.deleteCustomerRequest(data))
    } else {
      yield put(actions.deleteCustomerError(e.errors))
    }
  }
}

function* createCustomer({ data }: IAction) {
  try {
    const workspaceId = yield select(getWorkspaceActiveId)
    const response = yield call(api.createCustomer, workspaceId, data)

    const properties = yield select(getUserProperties)

    const userProps = data.properties.map(p => {
      const prop = properties.items.find(x => x.id === p.id)
      return {
        id: p.id,
        name: prop.name,
        type: prop.type,
        value: p.value
      }
    })

    yield put(actions.createCustomerSuccess({
      id: response.data.customer.id as number,
      uniqueId: response.data.customer.uniqueId as string,
      properties: userProps
    }))
  } catch (e: any) {
    if (e.errors && e.errors[0].code === 112) {
      yield call(refreshAccessToken)
      yield put(actions.createCustomerRequest(data))
    } else {
      yield put(actions.createCustomerError(e.errors))
    }
  }
}

function* updateUser({ data }: IAction) {
  try {
    const workspaceId = yield select(getWorkspaceActiveId)
    const { id, userId, val, restProp } = data
    const reqObj = {
      properties: [{
        id,
        value: val
      }, ...restProp]
    }

    yield call(api.updateCustomer, workspaceId, userId, reqObj)

  } catch (error) {
    // eslint-disable-next-line
    console.log('EEEEE', error)
  }
}

function* usersSaga(): any {
  yield takeLatest("GET_CUSTOMERS_REQUEST", getCustomers)
  yield takeLatest("SEARCH_CUSTOMERS_REQUEST", searchCustomers)
  yield takeLatest("DELETE_CUSTOMERS_REQUEST", deleteCustomer)
  yield takeLatest("CREATE_CUSTOMER_REQUEST", createCustomer)
  yield debounce(300, "EDIT_INTERNAL_USER", updateUser)
}

export default usersSaga