export const API_AUTH_BASE_URL = process.env.REACT_APP_API_AUTH_BASE_URL

export const API_WORKSPACE_BASE_URL = process.env.REACT_APP_API_WORKSPACE_BASE_URL

export const API_USERS_BASE_URL = process.env.REACT_APP_API_USERS_BASE_URL

export const API_USERS_V3_BASE_URL = process.env.REACT_APP_API_USERS_V3_BASE_URL

export const API_POSTS_BASE_URL = process.env.REACT_APP_API_POSTS_BASE_URL

export const API_FEED_BASE_URL = process.env.REACT_APP_API_FEED_BASE_URL